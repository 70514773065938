<template>
<v-container fluid>
    <v-row>
        <v-row>
            <v-col>
                <template>
                    <v-card>
                         <!-- Pending Orders Section -->
                        <v-row class="mt-5">
                            <!-- Data print & download  --> 
                            <v-col cols="4">
                                <v-card-title class="pb-10">
                                    <v-btn @click="exportTableToCSV('all_orders.csv')" text>
                                        <v-icon class="pr-5" large color="black">mdi-download </v-icon>
                                      </v-btn>
                                      <v-btn text @click="print">
                                        <v-icon class="pr-5" large color="black">mdi-printer </v-icon>
                                      </v-btn>
                                </v-card-title>
                            </v-col>
                            <!-- date filtering  -->
                            <v-col cols="8" class="d-flex flex-row">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field v-model="dateto1" label="From Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                        v-on="on" dense style="padding:0px !important"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateto1" @input="menu = false"></v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="2">
                                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field v-model="dateto" label="To Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                        v-on="on" dense style="padding:0px !important"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateto" @input="menu2=false"></v-date-picker>
                                  </v-menu>
                                </v-col>
                
                                <!-- <v-btn color="white" @click="todate">
                                  To Date
                                  <v-icon color="black" right>mdi-calendar-month </v-icon>
                                </v-btn> -->
                
                                <v-btn text @click="seereport" class="mr-2 font-weight-bold" style="background: gray">
                                  Submit
                                </v-btn>
                              </v-col>

                        </v-row>
                      <!-- hide-default-footer -->
                        <div id="dataapp" class="mt-4">
                            <v-data-table :headers="orderHeaders" :items="recentOrders" :search="search" :loading="loading" loading-text="Loading... Please wait" no-data-text="No orders available" item-key="id"
                            :server-items-length="totalItems" hide-default-footer class="elevation-1" style="background: #f5f5f5">
                                    <template v-slot:item.actions="{ item }">
                                        <v-btn color="primary" style="padding-left: 0px !important" text @click="viewDetailsToConfirmOrder(item)">Details</v-btn>
                                    </template>
                                    <template v-slot:top >
                                        <v-card-title>
                                            <v-spacer></v-spacer>
                                            <div class="pr-4 pb-4 pt-2 d-flex ">
                                                <div class="search g">
                                                    <v-text-field v-model="search_key" label="Search by order id, date" filled rounded   dense hide-details>
                                                    </v-text-field>

                                                </div>
                                                <div class="icon-search pr-4 pb-5 pt-2">
                                                    <v-btn icon style="background: red"><v-icon @click="searchHandle">mdi-magnify</v-icon></v-btn>
                                                </div> 
                                            </div>
                                        </v-card-title>
                                    </template>
                                    
                                    <template v-slot:item.actions_invoice="{ item }">
                                        <v-btn v-if="item.admin_status == 'Processing'" color="primary" style="padding-left: 0px !important" text @click="viewInvoice(item)">Invoice</v-btn>
                                    </template>                    
                        </v-data-table>
                        </div>
                        <div class="d-flex justify-content-center mt-5">
                            <div class="col-4">
                                Total {{ totalItems }} records
                            </div>
                            <div class="col-4">
                                <v-select dense outlined hide-details label="Items per page" v-model="perPage"  :items="pageSizes"  @change="handlePageSizeChange(perPage)">
                                </v-select>
                            </div>
                            <div class="col-4">
                                <v-pagination v-model="currentPage" :length="totalPages" total-visible="7" @input="handlePageChange">
                                </v-pagination>
                            </div>
                        </div>

                    </v-card>
                </template>
            </v-col>
        </v-row>
    </v-row>
</v-container>
</template>

<script>
export default {
    data: () => ({
        expanded: [],
        loading: true,
        date: new Date().toISOString().substr(0, 2),
        search: "",
        perPage: '10',
        currentPage: 1,
        totalItems: 0,
        totalPages: 0,
        pagination: {},
        pageSizes: ['10', '30', '50', '100', 'All'],
        singleExpand: false,
        dateto1: null,
        dateto: null,
        search_key: null,
        menu2: false,
        menu: false,
        order_status: null,
        orderHeaders: [{
                text: "Order ID",
                align: "start",
                value: "id",
            },
            {
                text: "Date",
                value: "ordered_date"
            },
            {
                text: "Client Name",
                value: "billing_address.name",
                sortable: false
            },
            {
                text: "Mobile Number",
                value: "billing_address.phone_number",
                sortable: false
            },
            {
                text: "Price ",
                value: "price_total"
            },
            {
                text: "Payment Method",
                value: "payment_method",
                sortable: false
            },
            {
                text: "Payment Status",
                value: "order_status"
            },
            {
                text: "Delivery Status",
                value: "delivery_status"
            },
            {
                text: "Delivery Location",
                value: "billing_address.location"
            },
           
            {
                text: "Invoice",
                value: "actions_invoice",
                sortable: false
            },
            
            {
                text: "Details",
                value: "actions",
                sortable: false
            },
            
            // { text: "Coupon Code", value: "coupon" },
            // { text: "Coupon Percentage", value: "coupon_percentage" },
            // { text: "Total Points", value: "point_total" },
            // { text: "Admin Confirmation", value: "adminaction", sortable: false },

            // { text: 'test', value: 'tes' },
            // { text: "", value: "data-table-expand" },
        ],
        recentOrders: [],
    }),
    
    methods: {
        searchHandle(event){
            this.initialize();
        },
        handlePageSizeChange(value) {
            this.perPage = (value);
            this.loading = true;
            this.initialize();
        },

        handlePageChange(value) {
            this.currentPage = value;
            this.loading = true;
            this.initialize();
            window.scrollTo(0, 0);
        },
        async initialize() {
            let perPage;
            if (this.perPage === 'All'){
                perPage = this.totalItems;
            }
            else{
                perPage = this.perPage;
            }
            this.loading = true;
            await axios.get(`Cart/admin_pending_orders/?page=${this.currentPage}&size=${perPage}&FromDate=${this.dateto1}&ToDate=${this.dateto}&order_status=${this.order_status}&search_key=${this.search_key}`).then((response) => {
                // console.log('response = ', response.status);
                if (response.status === 200) {
                    this.loading = false;
                    // console.log('results = ', response.data.results);
                    // console.log('orrecentOrdersder s= ', this.recentOrders);
                    this.totalItems = response.data.count;
                    this.totalPages = parseInt(this.totalItems / this.perPage);
                    this.recentOrders = response.data.results;
                    // console.log('orrecentOrdersder s= ', this.recentOrders);
                }
            });
        },
        
        viewInvoice(item) {
            if (item.reference_id == 0) {
                this.$router.push({
                    path: "/dashboard/pages/orderinvoice_updated",
                    query: {
                        id: item.id
                    },
                });                
            } 
            //Create open a new tab
            else {
                let routeData = this.$router.resolve({
                    name: "Invoice Page",
                    query: {
                        id: item.id
                    },
                });              
                // console.log('routedata = ', routeData);
              // dashboard/pages/edit-product-image?spm=600
                routeData.href = `/dashboard/pages/newInoice_page?id=${item.id}`;
                window.open(routeData.href, "_blank");               
            }
            // else {
            //     this.$router.push({
            //         path: "/dashboard/pages/newInoice_page",
            //         query: {
            //             id: item.id
            //         },
            //     });
            // }
        },

        viewMotherInvoice(item) {
            if (item.reference_id == 0) {
                this.$router.push({
                    path: "/dashboard/pages/orderinvoice_updated",
                    query: {
                        id: item.id
                    },
                });
            } else {
                // console.log("clicked");
                this.$router.push({
                    path: "/dashboard/pages/motherinvoice_updated",
                    query: {
                        id: item.reference_id
                    },
                });
            }
        },
        downloadCSV(csv, filename){
            var csvFile;
            var downloadLink;
           
            csvFile = new Blob([csv], {type: 'text/csv'});
            downloadLink = document.createElement("a");
            downloadLink.download = filename;
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = "none";

            document.body.appendChild(downloadLink);
            downloadLink.click();
    },
    
    exportTableToCSV(filename) {
      
          
          var csv = [];
          var rows = document.querySelectorAll(" #dataapp table tr");
        
          for(var i=0; i<rows.length; i++) {
           var row = [], cols = rows[i].querySelectorAll("td, th");
           for( var j=0; j<cols.length; j++)
              row.push(cols[j].innerText);
           csv.push(row.join(","));
          } 
         
          this.downloadCSV(csv.join("\n"), filename);
       },

       print() {
      var divToPrint = document.getElementById("dataapp");
      // var newWin = window.open("");
      // newWin.document.write(divToPrint.outerHTML);
      // newWin.print();

      //newWin.close();

      var style = "<style>";
      style = style + "table {width: 100%;font: 17px Calibri;}";
      style =
        style +
        "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
      style = style + "padding: 2px 3px;text-align: center;}";
      style = style + "</style>";

      var win = window.open("", "", "height=1900,width=1900");
      win.document.write(style); //  add the style.
      win.document.write(divToPrint.outerHTML);
      win.document.close();
      win.print();
    },

        fromdate() { },
        seereport() {
            // console.log('this from  date is :', this.dateto1);
            // console.log('this to  date is :', this.dateto);
            this.initialize();
    },
        todate() { },

    //     viewDetailsToConfirmOrder(item) {
    // //         this.$router.push({
    // //             path: "/dashboard/pages/confirmproduct",
    // //     query: { id: item.id },
    // //   });
    // },
        viewDetailsToConfirmOrder(item) {
            if (item.reference_id == 0) {
                this.$router.push({
                    path: "/dashboard/pages/confirmproduct",
                    query: {
                        id: item.id
                    },
                });                
            } 
            //Create open a new tab
            else {
                let routeData = this.$router.resolve({
                    name: "Details Page",
                    query: {
                        id: item.id
                    },
                });              
                // console.log('routedata = ', routeData);
              // dashboard/pages/edit-product-image?spm=600
                routeData.href = `/dashboard/pages/confirmproduct?id=${item.id}`;
                window.open(routeData.href, "_blank");               
            }
            // else {
            //     this.$router.push({
            //         path: "/dashboard/pages/newInoice_page",
            //         query: {
            //             id: item.id
            //         },
            //     });
            // }
        },

    },
    mounted() {
      this.initialize();
    },
};
</script>

<style>

</style>
